.home {
  width: 100vw;
  color: #E0E0E0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.home-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home-hero > h1 {
  color: rgb(233, 138, 49);
  font-size: 5rem;
  margin-bottom: 0px;
}

.home-hero > p {
  color: #E0E0E0;
}

@media only screen and (max-width: 600px) {
  .home-hero > h1 {
    font-size: 3rem;
  }
}
