.navbar {
  width: 98vw;
  padding: 0 1vw;
  height: 8vh;
  min-height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #303030;
}

.menu-btn {
  margin-right: 1rem;
  border: 1px solid transparent;
  background-color: transparent;
}

.menu-btn:hover {
  cursor: pointer;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}

.menu-btn-image {
  padding-top: 3px;
  height: 25px;
  width: 25px;
}

.navbar-mobile {
  width: 100vw;
  height: 8vh;
  min-height: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #303030;
}

.navbar-brand-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #E0E0E0;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: .5rem;
}

.navbar-mobile-brand-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #E0E0E0;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: .5rem;
}

.navbar-brand-image {
  width: auto;
  height: 90px;
  margin-right: .5rem;
}

.navbar-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
}

.navbar-mobile-navigation {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: 2;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.close-btn {
  font-size: 1.5rem;
  font-weight: 600;
  background-color: transparent;
  color: #E0E0E0;
  border: 1px solid transparent;
  margin-top: 1rem;
}

.close-btn:hover {
  cursor: pointer;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}

.navbar-navigation-link {
  color: #E0E0E0;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}

.navbar-mobile-navigation-link {
  color: #E0E0E0;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}

.navbar-navigation-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #info-link {
    display: none;
  }

  #contact-link {
    display: none;
  }
}
