.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #202020;
  color: #E0E0E0;
  overflow: hidden;
}

.page {
  width: 90vw;
  min-height: 92vh;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title {
  font-size: 2rem;
  color: #E0E0E0;
}

.page-section {
  width: 90%;
  max-width: 600px;
  background-color: #303030;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.page-section:hover {
  transform: scale(1.02);
}

.page-section-header {
  margin-bottom: 0px;
}

.btn {
  color: #ffffff;
  padding: .5rem 1rem;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.btn-primary {
  background-color: rgb(233, 138, 49);
}
